import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import { Config } from '@/config';
import { withStore, userStore } from '@/services/store';
import { TopNavigation, MainPage, TaskDetailPage, SettingsPage, CreateTaskPage } from '@/pages';
import {
  LoadingIndicator,
  NotificationsContainer,
  Banner,
  ErrorBoundary,
  ErrorBoundaryModal,
} from '@/components';
import { BASE_ROUTES, UrlSearch } from '@/types';
import {
  gtmConfigure,
  getRedirectURLAfterLogin,
  hideChromeBanner,
  isHideChromeBanner,
  removeRedirectURLAfterLogin,
  saveRedirectURLAfterLogin,
  convertToBool,
  getToken,
  isAppLoaded,
  setAppLoaded,
  clearAppLoaded,
} from './utils';
import { useMainStore } from './pages/main/stores/useMainStore';
import { setError } from './utils/errors';
import ForbiddenPage from './components/ForbiddenPage';
import { StatusCode } from './api/constants';

import './App.css';

function App() {
  // NOTE: Auth Process
  const { isLoading, isAuthenticated, error, loginWithRedirect, getAccessTokenSilently, logout } =
    useAuth0();
  const { routerStore } = useMainStore();

  useEffect(() => {
    if (userStore.currentUser?.id) {
      gtmConfigure(userStore.currentUser.id, userStore.organizationId);
    }
  }, [userStore.currentUser?.id]);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = Config.REACT_APP_AUTH0_DOMAIN;
      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: 'read:current_user',
        });
        userStore.login(accessToken);
      } catch (err) {
        setError(err);
      }
    };

    if (!isLoading && isAuthenticated) getUserMetadata();
  }, [isLoading, isAuthenticated]);

  // NOTE: App main
  const navigate = useNavigate();

  const isNotChrome =
    navigator.userAgent?.indexOf('Chrome') < 0 || navigator.userAgent?.indexOf('Edg/') !== -1;
  const [showBanner, setShowBanner] = useState(!isHideChromeBanner());
  const hideBannerClick = () => {
    hideChromeBanner();
    setShowBanner(false);
  };
  const searchParam = window.location.search;
  const urlParams = new URLSearchParams(searchParam);
  const bannerParam = urlParams.get(UrlSearch.hideBanner);
  const urlToken = urlParams.get(UrlSearch.token);
  const token = urlToken ?? getToken();

  const isLoggedIn = isAuthenticated || (token && routerStore.isValidToken);

  useEffect(() => {
    if (!isAppLoaded()) {
      setAppLoaded();
      window.location.reload();
    } else clearAppLoaded();
  }, []);

  useEffect(() => {
    if (isAuthenticated) routerStore.removeSearchParam(UrlSearch.token);
    else if (!isLoading) routerStore.setToken(token);
  }, [isLoading, isAuthenticated, token]);

  useEffect(() => {
    const root = document.documentElement;
    const isHideBanner = bannerParam ? convertToBool(bannerParam) : routerStore.isHideBanner;
    const bannerHeight = isHideBanner ? 0 : 60;

    routerStore.setHideBanner(isHideBanner);
    root.style.setProperty(
      '--app-banner-height',
      `${isNotChrome && showBanner ? 64 + bannerHeight : bannerHeight}px`,
    );
  }, [isNotChrome, showBanner, bannerParam]);

  useEffect(() => {
    document.title = userStore.organizationName;
  }, [userStore.organizationName]);

  useEffect(() => {
    if (routerStore.isValidToken) {
      const decodedJwt = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeout = setTimeout(() => {
        routerStore.removeToken();
      }, (decodedJwt.exp - currentTime + 1) * 1000);
      routerStore.removeSearchParam(UrlSearch.token);
      return () => clearTimeout(timeout);
    }
  }, [routerStore.isValidToken]);

  if (isLoading) {
    return <LoadingIndicator fullScreen />;
  }

  if (error) {
    console.error(error);
  }

  if (userStore.isUnAuthorizedError) {
    logout({ returnTo: window.location.origin });
  } else if (isLoggedIn) {
    if (!userStore.lastUrlLoaded && userStore.currentUser.id) {
      const redirectURL = getRedirectURLAfterLogin();
      userStore.setLastUrlLoaded();
      if (redirectURL) {
        if (isAuthenticated && urlParams.has('code')) navigate(redirectURL);
        removeRedirectURLAfterLogin();
        return;
      } else if (userStore.currentUser.filters?.former?.path) {
        if (window.location.pathname === BASE_ROUTES.main && !searchParam) {
          navigate(userStore.currentUser.filters.former.path);
        }
      }
    }

    if (userStore.apiCriticalIssue?.status === StatusCode.forbidden)
      return <ForbiddenPage title={userStore.apiCriticalIssue.preview} />;

    return (
      <ErrorBoundary>
        {isNotChrome && showBanner && <Banner onClose={hideBannerClick} />}
        <NotificationsContainer />
        <div className="App">
          {userStore.organizationLogo && !routerStore.isHideBanner && <TopNavigation />}
          <Routes>
            <Route path={BASE_ROUTES.main} element={<MainPage />} />
            <Route path={BASE_ROUTES.detail} element={<TaskDetailPage />} />
            <Route path={BASE_ROUTES.settings} element={<SettingsPage />} />
          </Routes>
        </div>

        {userStore.apiCriticalIssue && (
          <ErrorBoundaryModal
            url={userStore.apiCriticalIssue.url}
            payload={userStore.apiCriticalIssue.payload}
            preview={userStore.apiCriticalIssue.preview}
            statusText={userStore.apiCriticalIssue.statusText}
            isCritical={userStore.apiCriticalIssue.isCritical}
            isReload={userStore.apiCriticalIssue.isReload}
            errorAPIs={userStore.errorAPIs}
          />
        )}
      </ErrorBoundary>
    );
  } else if (!urlToken) {
    saveRedirectURLAfterLogin(`${window.location.pathname}${searchParam}`);
    loginWithRedirect();
    return null;
  }
}

export default withStore(observer(App));
